import {httpAuth} from './config';

export default {
    update:(userCompany) => {
        return httpAuth.put('v1/usuariosCompanhiasGrupos/' + userCompany.id, userCompany);
    }, 
    list: () => {
        return httpAuth.get('v1/usuariosCompanhiasGrupos/listarCompanhias/admin');
    }
}
