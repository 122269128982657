<template>
   <div>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
         <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <li class="nav-item" role="presentation">
               <a class="nav-link" :class="{ active: activeTab == 0 }" @click="activeTab = 0" id="allotment-tab"
                  data-toggle="tab" href="#allotment" role="tab" aria-controls="allotment"
                  aria-disabled="true">{{ t('NAV.USER') }}</a>
            </li>
         </div>
         <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <li class="nav-item" role="presentation">
               <a class="nav-link" :class="{ active: activeTab == 1 }" @click.stop.prevent="validateSecondAbaClick"
                  id="localization-tab" data-toggle="tab" href="#localization" role="tab"
                  aria-controls="localization"
                  aria-disabled="true">{{ t('GENERAL.PERMISSIONS') }}</a>
            </li>
         </div>
      </ul>
      <form @submit.prevent="create">
         <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" :class="{ active: activeTab == 0, show: activeTab == 0 }" id="allotment"
                 role="tabpanel" aria-labelledby="allotment-tab">

               <div class="modal-body">
                  <div class="row gutters">
                     <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-1">
                        <div class="custom-control custom-switch">
                           <input type="checkbox" class="custom-control-input" id="active"
                                  v-model="user.active">
                           <label class="custom-control-label" for="active">{{ t('ALLOTMENT.ACTIVE') }}</label>
                        </div>
                     </div>
                     <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                           <label for="name" class="required">{{ t('GENERAL.NAME') }}: </label>
                           <input type="text" id="name" name="name" v-model="user.name" class="form-control"
                                  @keyup="inputName(user.name)" placeholder="Nome Completo">
                           <div v-if="isNameInvalid" class="validation">{{ t(msgError) }}</div>
                        </div>
                     </div>
                     <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                           <label for="email" class="required">{{ t('GENERAL.EMAIL') }}: </label>
                           <input type="text" id="email" name="email" v-model="user.email" class="form-control"
                                  @keyup="clearMail()" placeholder="Endereço de e-mail">
                           <div class="validation" id="invalidEmail" style="display: none">{{ msgEmail }}</div>
                        </div>
                     </div>
                     <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                           <label for="email">{{ t('GENERAL.CRECI') }}: </label>
                           <input type="text" id="creci" name="creci" v-model="user.creci" class="form-control"
                           >
                        </div>
                     </div>
                     <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                           <label class="required" for="taxpayer">{{ t('COMPANIES.TAXPAYER') }}:</label>
                           <input type="text" id="taxpayer" name="taxpayer" v-model="user.taxpayer"
                                  @keyup="inputTaxpayer(user.taxpayer)" onpaste="return false"
                                  ondrop="return false" class="form-control"
                                  placeholder="000.000.000-00"
                                  v-mask="['###.###.###-##', '##.###.###/####-##']">
                           <div v-if="isTaxpayerInvalid" class="validation">{{ t(msgTaxpayer) }}</div>
                        </div>
                     </div>
                     <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="form-group">
                           <label for="birthdate">{{ (user?.taxpayer?.length > 14) ? t('COMPANIES.BIRTHDATE_COMAPANY') : t('COMPANIES.BIRTHDATE')}}:</label>
                           <input type="date" id="birthdate" name="birthdate" v-model="user.birthday" class="form-control">
                        </div>
                     </div>
                  </div>
                  <div class="row gutters">
                     <div class="col-xl-12 col-lglg-12 col-md-12 col-sm-12 col-12">
                        <phone :phonesIndex="phones" @update="updatePhone($event)" required></phone>
                     </div>
                  </div>
               </div>

            </div>
            <div class="tab-pane fade" :class="{ active: activeTab == 1, show: activeTab == 1 }" id="localization"
                 role="tabpanel" aria-labelledby="localization-tab">

               <div class="row gutters">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                     <table class="table table-bordered table-striped" id="company" v-if="openAllotments[0]">
                        <thead>
                        <tr>
                           <th style="width: 10px;"></th>
                           <th>{{ t('USERS.COMPANIES') }}</th>
                           <th>{{ t('GENERAL.GRUP') }}</th>
                        </tr>
                        </thead>
                        <tbody v-for="(company, key) in companies" :key="company.id">
                        <tr>
                           <td v-if="!openAllotments[key].open && openAllotments[key].id == company.id">
                              <button class="btn btn-holver bg-btn"
                                      @click.stop.prevent="openClose(key, !openAllotments[key].open, company)">
                                 <i class="icon-plus"/>
                              </button>
                           </td>
                           <td v-if="openAllotments[key].open && openAllotments[key].id == company.id">
                              <button class="btn btn-holver bg-btn"
                                      @click.stop.prevent="openClose(key, !openAllotments[key].open, company)">
                                 <i class="icon-minus"/>
                              </button>
                           </td>
                           <td @click="openClose(key, !openAllotments[key].open, company)">
                              <div class="pointer">{{ company.text }}</div>
                           </td>
                           <td>
                              <Select2
                                 :settings="{ width: '100%', placeholder: t('GENERAL.MSG.SELECT_ONE', {article: 'um', name: t('GENERAL.GRUP')})}"
                                 :options="profiles"
                                 v-model="company.group_id" :id="'profile'+company.id"
                                 :name="'profile'+company.id"
                                 @select="selectProfile($event, key, company, user.active), selectGeneric(company.group_id, company.id)"/>
                              <div class="validation" :id="'invalid'+company.id" style="display: none">
                                 {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                              </div>
                           </td>
                        </tr>
                        <tr v-if="openAllotments[key].open && openAllotments[key].id == company.id">
                           <td class="text-center">#</td>
                           <td class="text-center d-flex flex-row justify-content-around">
                              <span class="pr-4">Loteamento</span> <span class="pl-4">Corretagem</span></td>
                           <td class="text-center">Qtd. Reservas</td>
                        </tr>
                        <tr v-for="allotment in company.allotments" :key="allotment.id"
                            style="background-color: #fff">
                           <td v-if="openAllotments[key].open && openAllotments[key].id == company.id"
                               class="text-center">
                              <div class="custom-control custom-checkbox">
                                 <input data-cy="user_add_allotment" type="checkbox"
                                        class="custom-control-input"
                                        :id="'customCheck'+allotment.id" v-model="allotment.check"
                                        @click="selectAllotment(allotment, company.group_id, company.id)">
                                 <label class="custom-control-label"
                                        :for="'customCheck'+allotment.id"></label>
                              </div>
                           </td>
                           <td class="pl-xl d-flex flex-row justify-content-between"
                               v-if="openAllotments[key].open && openAllotments[key].id == company.id">
                              <span>{{ allotment.text }}</span>
                              <span>
                                 <div class="form-group">
                                    <div class="input-group align-self-center">
                                       <input class="form-control" type="text" id="brokerage" name="brokerage"
                                              v-model.lazy="allotment.brokerage_value"
                                              @focusout="setValueInAllotmentGroup(allotment.id, company.id, allotment)"
                                              v-money3="this.configBrokerage[allotment.id]">
                                       <div class="input-group-prepend">
                                    <button class="btn btn-outline-secondary dropdown-toggle" type="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       {{ t('GENERAL.TYPE') }}
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                       <a class="dropdown-item bg-hover-white"
                                          @click="resetConfigBrokerage(allotment, false)">{{
                                             t('GENERAL.REAIS')
                                          }}</a>
                                       <a class="dropdown-item bg-hover-white"
                                          @click="resetConfigBrokerage(allotment, true)">{{
                                             t('GENERAL.PERCENTAGE')
                                          }}</a>
                                    </div>
                                 </div>
                                    </div>
                                 </div>
                              </span>
                           </td>
                           <td v-if="openAllotments[key].open && openAllotments[key].id == company.id">
                              <input data-cy="user_create_max_reserve" type="text" id="maxReserves"
                                     name="maxReserves"
                                     v-model="allotment.max_number_reserves"
                                     class="form-control" :placeholder="t('GENERAL.QUANTITY_MAX_RESERVE')"
                                     @keyup="inputGeneric(allotment.max_number_reserves, 'maxReserves'),
                        selectMaxReserve(company.id, allotment.id, allotment.max_number_reserves)"
                                     :disabled="!allotment.check">
                              <div class="validation" id="invalidmaxReserves" style="display: none">
                                 {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                              </div>
                           </td>
                        </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
         <div class="modal-footer" style="justify-content: space-between">
            <div>
               <h5 class="ml-3" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
            </div>
            <div>
               <button data-cy="user_create_close" class="btn btn-secondary mr-2" data-dismiss="modal"
                       type="button">
                  {{ t('ACTIONS.CLOSE') }}
               </button>
               <button data-cy="user_create_next" class="btn btn-primary" type="button" v-if="activeTab == 0"
                       @click="validateSecondAbaClick()">{{ t('ALLOTMENT.NEXT') }}
               </button>
               <button data-cy="user_create_save" class="btn btn-primary" type="submit" v-if="activeTab == 1">
                  {{ t('ACTIONS.SAVE') }}
               </button>
            </div>
         </div>
      </form>
   </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import User from '@/services/user';
import avatar from "@/../public/avatar.png";
import {useToast} from "vue-toastification";
import Select2 from 'vue3-select2-component';
import Validate from '@/components/validate.vue';
import phone from '@/components/layouts/phoneComponent.vue';
import {Money3Directive} from 'v-money3';

export default {
   name: 'createUsers',
   props: ['userIndex', 'profilesIndex', 'companiesIndex'],
   mixins: [Validate],
   directives: {money3: Money3Directive},
   components: {
      phone,
      Select2,
   },

   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },

   data() {
      return {
         user: [],
         phones: [],
         profiles: [],
         activeTab: 0,
         companies: [],
         allotments: null,
         openAllotments: [],
         userCompaniesGroups: null,
         configBrokerage: []
      }
   },

   mounted() {
      this.user = this.userIndex.user;
      if (this.user.brokerage_is_percentage === undefined) this.user.brokerage_is_percentage = true;
      this.profiles = this.profilesIndex;

      this.indexCompany();
   },

   watch: {
      userIndex: function () {
         this.phones = [];
         this.activeTab = 0;
         this.openAllotments = [];
         this.user = this.userIndex.user;
         if (this.user.brokerage_is_percentage === undefined) this.user.brokerage_is_percentage = true;
         this.userCompaniesGroups = null;

         this.indexCompany();
         this.removeValidationsOpen();
      }
   },

   methods: {
      async indexCompany() {
         this.startIndex(this.companiesIndex);
      },

      setValueInAllotmentGroup(allotment_id, company_id, allotmentIndex){
         this.userCompaniesGroups.forEach(group => {
            if (group.company_id == company_id) {
               group.allotments.forEach(allotment => {
                  if (allotment.id == allotment_id) {
                     allotment.brokerage_value = allotmentIndex.brokerage_value;
                     allotment.brokerage_is_percentage = allotmentIndex.brokerage_is_percentage;
                  }
               });
            }
         });
      },

      resetConfigBrokerage(allotment ,type) {
         allotment.brokerage_value = 0;
         allotment.brokerage_is_percentage = type;
         this.setConfigBrokerage(allotment.id, type);
      },
      setConfigBrokerage(id, type) {
         if (type) {
            this.configBrokerage[id].max = 100;
            this.configBrokerage[id].prefix = '';
            this.configBrokerage[id].decimal = '.';
            this.configBrokerage[id].thousands = '';
            this.configBrokerage[id].suffix = '%';
            this.configBrokerage[id].disabled = false;
            this.configBrokerage[id].minimumNumberOfCharacters = 0;
         } else {
            this.configBrokerage[id].suffix = '';
            this.configBrokerage[id].decimal = ',';
            this.configBrokerage[id].thousands = '.';
            this.configBrokerage[id].prefix = 'R$ ';
            this.configBrokerage[id].disabled = false;
            this.configBrokerage[id].max = 100000000000;
            this.configBrokerage[id].minimumNumberOfCharacters = 1;
         }
      },

      clearMail() {
         if (this.user.email) {
            this.user.email = this.user.email.trim();
            this.user.email = this.user.email.toLowerCase();
         }
         this.inputEmail(this.user.email);
      },

      startIndex(companies) {
         let companiesAux = [];
         companies.forEach(company => {
            company.allotments.find(a => {
               a.check = a.check == true ? false : a.check;
            });
            companiesAux.push({id: company.id, text: company.text, group_id: null, allotments: company.allotments});
            this.openAllotments.push({id: company.id, open: false});
         });
         this.companies = companiesAux;
      },

      openClose(key, boolean, company) {
         company?.allotments?.forEach(i => {
            if (!this.configBrokerage[i.id]) {
               this.configBrokerage[i.id] = {};
               this.configBrokerage[i.id].max = 100;
               this.configBrokerage[i.id].prefix = '';
               this.configBrokerage[i.id].decimal = '.';
               this.configBrokerage[i.id].thousands = '';
               this.configBrokerage[i.id].suffix = '%';
               this.configBrokerage[i.id].disabled = false;
               this.configBrokerage[i.id].minimumNumberOfCharacters = 0;
            }
         })
         if (company.group_id) {
            this.openAllotments[key].open = boolean;
            document.getElementById('invalid' + company.id).style.display = 'none';
         } else {
            document.getElementById('invalid' + company.id).style.display = 'block';
         }
      },

      selectProfile({id}, key, company, active) {
         if (!this.userCompaniesGroups) {
            this.userCompaniesGroups = [];
            this.userCompaniesGroups.push({
               'group_id': id,
               'company_id': company.id,
               'active': active,
               allotments: []
            });
         } else {
            let alreadyHaveCompany = false;

            this.userCompaniesGroups.forEach(group => {
               if (group.company_id == company.id) {
                  group.group_id = id;
                  alreadyHaveCompany = true;
               }
            });

            if (!alreadyHaveCompany) {
               this.userCompaniesGroups.push({
                  'group_id': id,
                  'company_id': company.id,
                  'active': active,
                  allotments: []
               });
            }
         }
         this.openClose(key, true, company);
      },

      create() {
         if (this.checkUser()) {
            if (this.valitePermissionSelect()) {
               this.user.is_password_generic = true;
               this.$store.commit('changeLoading', true);
               this.user.avatar = avatar;

               let user = {
                  user: this.user,
                  userCompaniesGroups: this.userCompaniesGroups
               }
               let send = {email: this.user.email};
               user.userCompaniesGroups.forEach(group => {
                  group.allotments.forEach(item => {
                     let brokerageValueFinal = null;
                     if (item.brokerage_is_percentage || item.brokerage_is_percentage === undefined && item.brokerage_value) {
                        brokerageValueFinal = item.brokerage_value.replaceAll("%", "");
                        brokerageValueFinal = brokerageValueFinal.replaceAll(",", ".");
                     } else if(item.brokerage_value) {
                        brokerageValueFinal = item.brokerage_value.replaceAll("R$ ", "");
                        brokerageValueFinal = brokerageValueFinal.replaceAll(".", "");
                        brokerageValueFinal = brokerageValueFinal.replaceAll(",", ".");
                     }
                     item.brokerage_value = brokerageValueFinal;
                  })
               })
               User.create(user).then(() => {
                  document.getElementById('closeX').click();
                  this.toast.success(this.t('GENERAL.MSG.REGISTER_SUCCESS'));
                  User.sendEmailRegister(send).then().catch(error => {
                     this.errorMsg(error)
                  });
                  this.$emit('create');
                  this.$store.commit('changeLoading', false);
               }).catch(error => {
                  this.$store.commit('changeLoading', false);
                  this.errorMsg(error);
               });
            }
         }
      },

      checkUser() {
         this.invalid = [];

         this.nameValidate(this.user.name);
         this.emailValidate(this.user.email);
         this.validateTaxpayer(this.user.taxpayer);

         if (this.invalid.length && this.invalid.length > 0) {
            return false;
         }
         if (!this.phones?.length){
            this.toast.error('Campo Telefone é obrigatorio');
            return false;
         }
         return true;
      },

      selectAllotment(allotment, group_id, company_id) {
         this.userCompaniesGroups.forEach(group => {
            if (group.company_id == company_id) {
               if (!group.allotments[0]) {
                  let brokerageValueFinal = null
                  if (allotment.brokerage_is_percentage || allotment.brokerage_is_percentage === undefined) {
                     brokerageValueFinal = allotment.brokerage_value.replaceAll("%", "");
                     brokerageValueFinal = brokerageValueFinal.replaceAll(",", ".");
                  } else {
                     brokerageValueFinal = allotment.brokerage_value.replaceAll("R$ ", "");
                     brokerageValueFinal = brokerageValueFinal.replaceAll(".", "");
                     brokerageValueFinal = brokerageValueFinal.replaceAll(",", ".");
                  }
                  group.allotments.push({
                     'id': allotment.id,
                     'is_realtor': group_id == 2 ? true : false,
                     'max_number_reserves': allotment.max_number_reserves,
                     'brokerage_is_percentage': allotment.brokerage_is_percentage === undefined ? true: allotment.brokerage_is_percentage,
                     'brokerage_value': brokerageValueFinal,
                  });
               } else {
                  var isAddAllotment = true;

                  group.allotments.forEach((dataAllotment, key) => {
                     if (allotment.check) {
                        if (dataAllotment.id == allotment.id) {
                           group.allotments.splice(key, 1);
                           isAddAllotment = false;
                        }
                     }
                  });

                  if (isAddAllotment) {
                     group.allotments.push({
                        'id': allotment.id,
                        'is_realtor': group_id == 2 ? true : false,
                        'max_number_reserves': allotment.max_number_reserves
                     });
                  }
               }
            }
         });
      },

      selectMaxReserve(company_id, allotment_id, max_number_reserves) {
         this.userCompaniesGroups.forEach(group => {
            if (group.company_id == company_id) {
               group.allotments.forEach(allotment => {
                  if (allotment.id == allotment_id) {
                     allotment.max_number_reserves = max_number_reserves;
                  }
               });
            }
         });
      },

      valitePermissionSelect() {
         let invalid = false;
         if (!this.userCompaniesGroups) {
            this.invalid.push(true);
            this.toast.error(this.t('GENERAL.MSG.SELECT_ONE_COMPANY'), {timeout: false});
            invalid = true;

         } else {
            this.userCompaniesGroups.forEach(userCompanie => {
               if (userCompanie.allotments.length == 0) {
                  invalid = true;
                  this.invalid.push(true);
                  this.toast.error(this.t('GENERAL.MSG.SELECT_ONE_ALLOTMENT_IN_COMPANIES'), {timeout: false});
               }
            });
         }

         return !invalid;
      },

      removeValidationsOpen() {
         this.isNameInvalid = false;
         document.getElementById('name').classList.remove('is-valid');
         document.getElementById('email').classList.remove('is-valid');
         document.getElementById('name').classList.remove('is-invalid');
         document.getElementById('invalidEmail').style.display = 'none';
         document.getElementById('email').classList.remove('is-invalid');
      },

      validateSecondAbaClick() {
         if (this.checkUser()) {
            this.activeTab = 1;
         }
      },

      updatePhone(phones) {
         this.user.phones = phones;
      }
   }
}
</script>

<style scoped>
.btn-holver {
   border: none;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   transition-duration: 0.4s;
   cursor: pointer;
}

.bg-btn {
   background-color: #f2f4f9 !important;
   color: black !important;
}

.bg-btn:hover {
   background-color: #0e5e4e !important;
   color: white !important;
}

.custom-checkbox {
   padding-left: 32px !important;
}

.pointer {
   cursor: pointer;
}
</style>
