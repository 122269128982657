<template>
   <div>
      <div class="modal-content" id="myTabContent">
         <div class="" id="allotment">
            <div class="modal-body">
               <div class="row gutters">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                     <div class="form-group">
                        <label for="name" class="required">{{ t('GENERAL.NAME') }}: </label>
                        <input type="text" id="name" name="name" v-model="user.name" class="form-control"
                               @keyup="inputName(user.name)" placeholder="Nome Completo">
                        <div v-if="isNameInvalid" class="validation">{{ t(msgError) }}</div>
                     </div>
                  </div>
                  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                     <div class="form-group">
                        <label for="email" class="required">{{ t('GENERAL.EMAIL') }}: </label>
                        <input type="text" id="email" name="email" v-model="user.email" class="form-control"
                               placeholder="Endereço de e-mail" disabled>
                        <div class="validation" id="invalidEmail" style="display: none">{{ msgEmail }}</div>
                     </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                     <div class="form-group">
                        <label for="creci">{{ t('GENERAL.CRECI') }}: </label>
                        <input type="text" id="creci" name="creci" v-model="user.creci" class="form-control">
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                     <div class="form-group">
                        <label class="required" for="taxpayer">{{ t('COMPANIES.TAXPAYER') }}:</label>
                        <input type="text" id="taxpayer" name="taxpayer" v-model="user.taxpayer"
                               @keyup="inputTaxpayer(user.taxpayer)" onpaste="return false"
                               ondrop="return false" class="form-control"
                               placeholder="000.000.000-00"
                               v-mask="['###.###.###-##', '##.###.###/####-##']">
                        <div v-if="isTaxpayerInvalid" class="validation">{{ t(msgTaxpayer) }}</div>
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                     <div class="form-group">
                        <label
                           for="birthdate">{{ (user?.taxpayer?.length > 14) ? t('COMPANIES.BIRTHDATE_COMAPANY') : t('COMPANIES.BIRTHDATE') }}:</label>
                        <input type="date" id="birthdate" name="birthdate" v-model="user.birthday" class="form-control">
                     </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                     <phone-component :phones-index="user.phones" @update="updatePhone($event)"></phone-component>
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <button class="btn btn-primary" @click="editUser()">Salvar</button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import phoneComponent from '../../components/layouts/phoneComponent';
import user from '../../services/user';
import Validate from '@/components/validate.vue';
import errorMsg from '../../components/errorMsg';

export default {
   name: "editUserComponent",
   props: {
      itemIndex: {
         type: Object,
         required: true,
      }
   },
   mixins: [errorMsg,Validate],
   components: {
      phoneComponent,
   },
   data() {
      return {
         user: {},
      }
   },
   mounted() {
      this.user = this.itemIndex;
   },
   methods: {
      checkUser() {
         this.invalid = [];

         this.nameValidate(this.user.name);
         this.emailValidate(this.user.email);
         this.validateTaxpayer(this.user.taxpayer);

         if (this.invalid.length && this.invalid.length > 0) {
            return false;
         }
         if (!this.user.phones?.length){
            this.toast.error('Campo Telefone é obrigatorio');
            return false;
         }
         return true;
      },
      editUser(){
         if (!this.checkUser()) {
            return;
         }
         this.$store.commit('changeLoading', true);
         user.update(this.user).then(() => {
            this.$emit('updated');
            document.getElementById('closeX')?.click();
            this.toast.success(this.t('GENERAL.UPDATED_SUCCESSFULL'));
         }).catch((err) => {
            this.$store.commit('changeLoading', false);
            this.errorMsg(err);
         })
      },
      updatePhone(phones) {
         this.user.phones = phones;
      }
   },
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
}
</script>

<style scoped>
.btn-holver {
   border: none;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   transition-duration: 0.4s;
   cursor: pointer;
}

.bg-btn {
   background-color: #f2f4f9 !important;
   color: black !important;
}

.bg-btn:hover {
   background-color: #0e5e4e !important;
   color: white !important;
}

.custom-checkbox {
   padding-left: 32px !important;
}

.pointer {
   cursor: pointer;
}
</style>
